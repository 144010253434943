.Loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    min-width: 100vw;
    text-align: center;
}

.Loader {
    display: block;
    width: 80px;
    height: 80px;
    margin: 10px auto;
}
