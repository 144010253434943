.guide-dialog {
    .MuiDialog-paper {
        @include mq($from: medium) {
            max-width: 636px;
        }
        @include mq($from: large) {
            max-width: 798px;
        }
    }
}

.guide-wrapper {
    &__nav {
        position: sticky;
        display: flex;
        justify-content: flex-end;
        padding-right: 18px;
        top: 18px;
    }
}

.guide {
    padding: 30px 18px 30px;
    @include mq($from: medium) {
        padding: 36px 24px 36px;
    }

    a {
        text-decoration-color: theme-color(purple);
        text-decoration-thickness: 5px;
    }

    &__more-btn {
        font-size: 18px;
        font-weight: 600;
        background-color: theme-color(purple);
        color: theme-color(black);
        transition: background-color 0.15s linear;

        &:hover {
            background-color: lighten(theme-color(purple), 3%);
        }
    }
}
