//====================================
// Containers and wrappers
//====================================

body {
    position: relative;
    background-color: #fff;
}

.max-width {
    max-width: 750px;
}

.max-width-small {
    max-width: 405px;
}

.centered {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.outer-padding-x {
    padding-left: $outer-padding;
    padding-right: $outer-padding;
    @include mq($from: medium) {
        padding-left: $outer-padding-medium;
        padding-right: $outer-padding-medium;
    }
    @include mq($from: large) {
        padding-left: $outer-padding-large;
        padding-right: $outer-padding-large;
    }
    @include mq($from: xxlarge) {
        padding-left: $outer-padding-xlarge;
        padding-right: $outer-padding-xlarge;
    }
}

.outer-padding-y {
    padding-top: $outer-padding-y;
    padding-bottom: $outer-padding-y;
    @include mq($from: medium) {
        padding-top: $outer-padding-y-medium;
        padding-bottom: $outer-padding-y-medium;
    }
    @include mq($from: large) {
        padding-top: $outer-padding-y-large;
        padding-bottom: $outer-padding-y-large;
    }
    @include mq($from: xxlarge) {
        padding-top: $outer-padding-y-xxlarge;
        padding-bottom: $outer-padding-y-xxlarge;
    }
}
