.Intro {
    display: flex;
    justify-content: space-between;

    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

    &__content {
        @include mq($from: xlarge) {
            width: 70%;
        }
        p {
            @include build-font(paragraph-big);
            @include mq($until: medium) {
                @include build-font(paragraph-phone);
            }
            max-width: 800px;
            margin-bottom: 0;
            white-space: pre-line;
        }
        h1 {
            @include mq($until: medium) {
                @include build-font(headline-3);
                margin-bottom: 6px;
            }
        }
    }
    &__illustration {
        display: none;
        width: 347px;
        height: 300px;
        object-fit: fill;
        object-position: left;
        margin-left: 24px;

        @include mq($from: xlarge) {
            display: block;
        }
    }
}
