.MuiDialog-paper {
    @include mq($until: medium) {
        width: calc(100vw - 30px);
        height: calc(100vh - 32px);
        max-width: unset !important;
    }
}

.la-modal {
    display: flex;
    flex-direction: column;

    height: 100%;
    padding: 18px 18px 24px;

    @include mq($from: medium) {
        padding: 36px;
    }
    @include mq($from: large) {
        padding: 42px;
    }
    background-color: white;

    p {
        white-space: pre-line;
        margin-bottom: 36px;
    }
}

.la-modal__form {
    display: flex;
    flex-direction: column;
    height: 100%;

    @include mq($from: medium) {
        min-width: calc(500px - 24px);
    }
}

.la-modal__actions {
    display: flex;
    align-self: flex-end;
    margin-top: 36px;

    > button,
    input {
        margin-left: 12px;
    }

    @include mq($until: medium) {
        margin-top: auto;
        margin-bottom: 30px;
        align-self: center;
        flex-wrap: wrap-reverse;

        > button,
        input {
            margin: 0 auto;

            &:last-child {
                width: 100%;
            }

            &:last-child {
                margin-bottom: 6px;
            }
        }
    }
}
