.referral-survey {
    align-items: center;

    > h3 {
        margin-bottom: 42px;
        text-align: center;
    }

    button:disabled {
        cursor: progress;
    }

    > button {
        margin-bottom: 12px;
        width: 300px;

        @include mq($until: medium) {
            width: 100%;
            &:first-of-type {
                margin-top: 60px;
            }
        }
    }

    &--loading {
        cursor: progress;
    }
}
