.Nav {
    height: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    padding: 3px;
    > ul {
        margin-bottom: 40px;
    }
    li {
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &__logout {
        text-align: left;
        &:hover {
            background-color: util-color(error-bg);
        }
    }

    Button {
        width: 100%;
        border: none;
        background-color: white;
        color: theme-color(black);
        padding: 8px;
        @include mq($from: medium) {
            padding: 12px;
        }

        &:hover {
            background-color: unset;
        }

        > span {
            margin: 0;
            min-width: 120px;
            text-align: left;
        }

        > svg {
            margin-right: 10px;
            width: 40px;
            height: 40px;
            fill: theme-color(black);
        }
    }
}

a.NavItem {
    margin-bottom: 10px;
}

.NavItem {
    display: flex;
    align-items: center;
    height: auto;
    width: 100%;
    padding: 8px;
    color: theme-color(black);
    transition: background-color 0.2s ease;
    @include mq($from: medium) {
        padding: 12px;
    }

    &:focus {
        outline: 2px dotted darken(theme-color(lightblue), 25%);
    }

    svg {
        display: block;
        width: 32px;
        height: 32px;
        fill: theme-color(black);
        margin-right: 16px;
    }
    span {
        @include build-font("paragraph");
        line-height: 1;
        font-weight: 600;
        min-width: 120px;
    }

    &--red {
        &:hover {
            background-color: transparentize(theme-color(red), 0.3);
        }
        &.current-page {
            background-color: theme-color(red);
        }
    }
    &--pink {
        &:hover {
            background-color: transparentize(theme-color(pink), 0.3);
        }
        &.current-page {
            background-color: theme-color(pink);
        }
    }
    &--purple {
        &:hover {
            background-color: transparentize(theme-color(purple), 0.3);
        }
        &.current-page {
            background-color: theme-color(purple);
        }
    }
    &--yellow {
        &:hover {
            background-color: transparentize(theme-color(yellow), 0.3);
        }
        &.current-page {
            background-color: theme-color(yellow);
        }
    }
    &--green {
        &:hover {
            background-color: transparentize(theme-color(green), 0.3);
        }
        &.current-page {
            background-color: theme-color(green);
        }
    }
    &--lightblue {
        &:hover {
            background-color: transparentize(theme-color(lightblue), 0.3);
        }
        &.current-page {
            background-color: theme-color(lightblue);
        }
    }
    &--lightgrey {
        &:hover {
            background-color: transparentize(theme-color(lightgrey), 0.3);
        }
        &.current-page {
            background-color: theme-color(lightgrey);
        }
    }
}
