.Module {
    position: relative;
    display: block;
    width: 100%;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease-in-out;
    .Button {
        transition: all 0.3s ease-in-out;
        svg {
            transition: all 0.3s ease-in-out;
        }
    }
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        opacity: 0;
        border-radius: 5px;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
        transition: opacity 0.3s ease-in-out;
        border-radius: inherit;
    }
}

a.Module,
button.Module {
    &:hover {
        transform: translateY(-5px);
        &::after {
            opacity: 1;
        }
        @include mq($from: medium) {
            .Button {
                background-color: theme-color(black);
                color: theme-color(white);
                svg {
                    fill: theme-color(white);
                }
            }
        }
    }
}
