.partner {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__img {
        max-width: 384px;
        padding: 6px;
        margin-bottom: 16px;
    }

    &__desc {
        max-width: 600px;
    }
}
