.compact {
    display: flex;
    flex-direction: column;

    @include mq($from: medium) {
        width: calc(560px - 72px);
    }
    @include mq($from: large) {
        width: calc(560px - 84px);
    }
    height: 100%;
    @include mq($from: medium) {
        height: 641px;
    }
    @include mq($from: large) {
        height: 653px;
    }

    &__title {
        display: flex;
        justify-content: space-between;
        @include mq($from: medium) {
            align-items: center;
        }
        margin-bottom: 18px;

        > .la-fab {
            margin-right: 12px;
        }

        > h4 {
            text-align: right;
            margin-bottom: 0;
        }
    }
}
