.color-white {
    color: theme-color(white);
}

.color-black {
    color: theme-color(black);
}

.bg-white {
    background-color: theme-color(white);
}

.bg-black {
    background-color: theme-color(black);
}

.bg-red {
    background-color: theme-color(red);
}

.bg-pink {
    background-color: theme-color(pink);
}

.bg-purple {
    background-color: theme-color(purple);
}

.bg-yellow {
    background-color: theme-color(yellow);
}

.bg-green {
    background-color: theme-color(green);
}

.bg-blue {
    background-color: theme-color(blue);
}

.bg-lightblue {
    background-color: theme-color(lightblue);
}

.bg-lightgray {
    background-color: theme-color(lightgray);
}

.bg-lightergray {
    background-color: theme-color(lightergray);
}

.bg-orange {
    background-color: theme-color(orange);
}

.rounded-borders {
    border-radius: $default-border-radius;
}

.padding-xy {
    padding: 18px;
    @include mq($from: large) {
        padding: 36px;
    }
}

.padding-x {
    padding-left: 18px;
    padding-right: 18px;
    @include mq($from: large) {
        padding-left: 36px;
        padding-right: 36px;
    }
}

.overflow-hidden {
    overflow: hidden;
}

.margin-bottom {
    margin-bottom: 20px;
}

.margin-bottom-big {
    margin-bottom: 40px;
}

.card-spacing {
    margin-bottom: 16px;
}

.box-shadow {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.info {
    width: 100%;
    padding: 18px;
    text-align: center;
    background-color: theme-color(lightblue);
    border-radius: $default-border-radius;
    margin-bottom: 42px;
    align-self: center;

    p:first-child {
        font-weight: 600;
        margin-bottom: 0;
    }

    p:last-child {
        max-width: 360px;
        margin: 0 auto 6px;
    }

    button {
        font-weight: 600;
        color: theme-color(black);
        text-decoration: underline;
    }
}

.list {
    display: flex;
    flex-direction: column;

    > .Module {
        @include mq($until: medium) {
            padding-bottom: 0;
        }
    }
}
