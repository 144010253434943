.la-input-field {
    position: relative;
    margin-bottom: 18px;
    @include mq($from: medium) {
        margin-bottom: 24px;
    }

    &__label {
        padding: 18px 13px 6px;
        @include mq($from: medium) {
            padding: 18px 19px 6px;
        }
        @include build-font(paragraph-big);
        @include mq($until: medium) {
            @include build-font(paragraph-phone);
        }
        margin-bottom: 6px;
    }

    &__suffix {
        position: absolute;
        opacity: 0.55;
        font-size: 18px;
        @include mq($from: medium) {
            font-size: 1.5rem;
        }
        bottom: 12px;
        right: 24px;
        @include mq($from: medium) {
            bottom: 17px;
            right: 36px;
        }
    }

    &.invisible {
        display: none;
    }
}

.la-input-label {
    display: block;
    padding-left: 13px;
    @include mq($from: medium) {
        padding-left: 19px;
    }
    margin-bottom: 2px;
    opacity: 0.8;
    font-size: 13px;
    @include mq($from: medium) {
        font-size: 1.2rem;
    }
}

.la-input {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 18px;
    @include mq($from: medium) {
        font-size: 1.5rem;
    }
    width: 100%;
    padding: 12px;
    @include mq($from: medium) {
        padding: 18px;
    }
    border: 1px solid #e9e9e9;
    border-radius: $default-border-radius;
    background-color: white;

    &--small {
        font-size: 16px;
        @include mq($from: medium) {
            font-size: 1.125rem;
        }
        padding: 19px;
    }

    transition: border-color 200ms linear, background-color 200ms linear;

    &:hover {
        border-color: theme-color(black);
    }

    &:focus {
        outline: none;
    }

    &--filled {
        border-color: theme-color(black);
        background-color: theme-color(lightblue);
    }

    &.add-more-placeholder {
        border-width: 2px;
        border-style: dashed;
        text-align: center;
        color: lightgray;
        font-style: normal;
        border-color: lightgray;
    }
}
