.home-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;

    @include mq($until: medium) {
        flex-wrap: wrap;
    }

    &__content {
        > p {
            @include build-font(paragraph-big);
        }

        @include mq($until: medium) {
            width: 100%;

            > h2 {
                @include build-font(headline-3);
                margin-bottom: 3px;
            }

            > p {
                @include build-font(paragraph-phone);
                margin-bottom: 0;
            }

            > button {
                position: absolute;
                top: 21px;
                right: 15px;
                border: none;
                padding: 0;
                min-width: unset;
                > span {
                    display: none;
                }

                &:hover {
                    background-color: transparent;
                    > svg {
                        fill: theme-color(black);
                    }
                }
            }
        }
    }

    &__img {
        width: 288px;
        height: 216px;
        object-fit: contain;
        object-position: right;
        margin-left: 24px;

        @include mq($until: medium) {
            margin: 0 auto;
            object-position: center;
        }
    }
}
