.brand-preview-wrapper {
    width: 100%;
    @include mq($from: xlarge) {
        width: 50%;
    }
}
.brand-preview {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: $default-border-radius;

    &__copy {
        position: relative;
        flex-basis: 100%;

        &__txt {
            font-size: 18px;
            @include mq($from: medium) {
                font-size: 24px;
            }
            font-weight: 600;
        }

        &__chip {
            position: absolute;
            font-weight: 600;
            background-color: theme-color(green);
            @include mq($until: medium) {
                padding: 0 12px;
                margin-top: 4px;
                font-size: 14px;
                height: 19px;
                line-height: 18px;
                border-radius: 9.5px;
            }
            padding: 0 15px;
            margin-top: 6px;
            height: 26px;
            line-height: 24px;
            border-radius: 13px;
        }
    }

    &__img {
        width: 72px;
        height: 72px;
        margin-left: 6px;
        @include mq($from: medium) {
            width: 120px;
            height: 120px;
            margin-left: 18px;
        }
        object-fit: contain;
    }

    i {
        font-size: 60px;
        padding: 6px;
        margin-left: 6px;
        @include mq($from: medium) {
            font-size: 96px;
            padding: 12px;
            margin-left: 18px;
        }
    }

    &__letter {
        flex-shrink: 0;
        width: 72px;
        height: 72px;
        margin-left: 6px;
        padding: 6px;
        background-color: theme-color(lightblue);
        border-radius: 50%;
        font-family: "DM Serif Display", serif;
        font-size: 48px;
        text-align: center;
        @include mq($from: medium) {
            font-size: 72px;
            width: 120px;
            height: 120px;
            margin-left: 18px;
            padding: 12px;
        }

        &--add {
            background-color: white;
            font-weight: bold;
            font-size: 84px;
            line-height: 1.145;
            @include mq($until: medium) {
                font-size: 56px;
                line-height: 1.085;
            }
        }
    }
}
