$theme-fonts: (
    "headline-1": (
        default: (
            "font-size": rem-calc(36px),
            "line-height": 1.2,
            "margin-bottom": 20px,
        ),
        medium: (
            "font-size": rem-calc(42px),
        ),
        large: (
            "font-size": rem-calc(52px),
        ),
    ),
    "headline-2": (
        default: (
            "font-size": rem-calc(38px),
            "line-height": 1.2,
            "margin-bottom": 20px,
        ),
        large: (
            "font-size": rem-calc(42px),
        ),
    ),
    "headline-3": (
        default: (
            "font-size": rem-calc(28px),
            "line-height": 1.2,
            "margin-bottom": 20px,
        ),
        medium: (
            "font-size": rem-calc(34px),
        ),
    ),
    "headline-4": (
        default: (
            "font-size": rem-calc(26px),
            "line-height": 1.2,
        ),
    ),
    "paragraph": (
        default: (
            "font-size": rem-calc(16px),
            "line-height": 1.45,
        ),
        medium: (
            "font-size": rem-calc(18px),
        ),
    ),
    "paragraph-phone": (
        default: (
            "font-size": rem-calc(16px),
            "line-height": 1.45,
        ),
    ),
    "paragraph-small": (
        default: (
            "font-size": rem-calc(14px),
        ),
    ),
    "paragraph-big": (
        default: (
            "font-size": rem-calc(20px),
            "line-height": 1.4,
        ),
        medium: (
            "font-size": rem-calc(24px),
        ),
    ),
) !default;
