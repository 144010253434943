.print-item {
    @include mq($from: xlarge) {
        width: 50%;
    }
    > .Module {
        width: 100%;
    }

    p {
        position: absolute;
        text-align: left;
        opacity: 0.5;
        @include mq($until: large) {
            font-size: 12px !important;
            bottom: 5px;
        }
        font-size: 18px !important;
    }

    &--loading {
        pointer-events: none;
    }
}
