.undertaker-search {
    width: 600px;
    max-width: 100%;

    &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 18px;

        > h4 {
            margin: 0;
        }
    }

    &__show-all-btn {
        @include mq($until: medium) {
            width: 100%;
        }
    }

    &__empty {
        text-align: center;
        margin-top: 36px;
    }
}

.undertaker-preview {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid theme-color(lightgrey);
    border-radius: $default-border-radius;
    width: 100%;
    padding: 18px;
    margin-bottom: 18px;

    &__txt {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        line-height: 1.2;
        text-align: left;

        > div {
            &:first-of-type {
                color: theme-color(black);
                font-weight: 600;
            }
            &:last-of-type {
                color: theme-color(grey);
            }
        }
    }

    &__img {
        width: 72px;
        height: 72px;
        margin-left: 6px;
        object-fit: contain;
    }
}

.undertaker {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 18px 18px 24px;
    height: 100%;
    @include mq($from: medium) {
        padding: 36px;
    }
    @include mq($from: large) {
        padding: 42px;
    }

    &__img {
        width: 240px;
        height: 20%;
        object-fit: contain;
        margin-top: -12px;
    }

    &__name {
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        margin-top: 12px;
    }

    > p {
        text-align: center;
        line-height: 1.35;
        margin: 6px 0 30px;
    }

    &__details {
        list-style: none;
        padding-left: 0;
        text-align: center;
        width: 100%;
        margin-bottom: 36px;

        @include mq($until: medium) {
            margin-top: auto;
            margin-bottom: 36px;
        }

        > li {
            height: 30px;
        }

        span {
            display: inline-block;
            font-weight: 600;
            height: 28px;
            margin-left: 6px;
            max-width: 264px;
            @include mq($from: medium) {
                max-width: 312px;
            }
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
        }
    }

    > .Button:last-of-type {
        width: 100%;
    }
}
