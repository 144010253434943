.charity-intro {
    display: flex;
    align-items: center;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    background-color: white;

    @include mq($until: xlarge) {
        flex-wrap: wrap-reverse;
    }
    justify-content: center;
    @include mq($from: xlarge) {
        justify-content: space-between;
    }

    &__copy {
        max-width: 800px;
        > p {
            margin-bottom: 0;
            @include mq($from: medium) {
                font-size: 1.5rem;
            }
        }
        @include mq($from: xlarge) {
            width: 70%;
        }
        @include mq($until: xlarge) {
            text-align: center;
        }

        > h1 {
            @include mq($until: xlarge) {
                font-family: "Source Sans Pro", sans-serif;
                font-weight: 600;
                font-size: 32px;
                margin-bottom: 12px;
            }
            @include mq($until: medium) {
                font-size: 18px;
            }
        }
    }

    &__logo {
        object-fit: contain;

        @include mq($from: small) {
            max-width: 96px;
            max-height: 96px;
        }
        @include mq($from: medium) {
            max-width: 168px;
            max-height: 168px;
        }
        @include mq($until: xlarge) {
            margin-bottom: 24px;
        }
        @include mq($from: xlarge) {
            max-width: 240px;
            max-height: 240px;
            margin-left: 48px;
        }
    }
}

.charity-other-intro {
    display: flex;
    align-items: center;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    background-color: white;

    &__copy {
        max-width: 800px;
        > p {
            margin-bottom: 0;
            @include mq($from: medium) {
                font-size: 1.5rem;
            }
        }
        @include mq($from: xlarge) {
            width: 70%;
        }

        > h1 {
            @include mq($until: xlarge) {
                margin-bottom: 12px;
            }
        }
    }
}
