//
// Font family
//
$theme-font-family: "Source Sans Pro", Arial, Helvetica, Geneva, serif !default;
$theme-font-family-headlines: "DM Serif Display", Arial, Helvetica, Geneva, sans-serif !default;

//====================================
// Includes
//====================================
@import "headlines";
@import "paragraphs";
@import "lists";
@import "links";
@import "buttons";
@import "labels";
@import "inputs";

@mixin typography-reset {
    font-family: $theme-font-family;
    font-size: 100%;
    line-height: 1.3;
    font-weight: 400;
    color: theme-color(black);
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

//
// Default Styles
//
@mixin typography-everything {
    html {
        @include typography-reset;
    }

    @include typography-links;
    @include typography-headlines;
    @include typography-paragraphs;
    @include typography-lists;
    @include typography-buttons;
}

::selection {
    background-color: theme-color(lightblue);
    color: theme-color(black);
}

body {
    b,
    strong {
        font-weight: bold;
    }
}

hr {
    margin: 20px 0;
    border: none;
    border: 2px solid theme-color(lightgray);
}
