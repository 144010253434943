.Form {
    input[type="text"],
    input[type="email"],
    input[type="tel"],
    input[type="password"],
    input[type="url"],
    input[type="number"],
    input[type="search"],
    textarea,
    select {
        display: block;
        width: 100%;
        @include build-font(paragraph);
        padding: 0 16px;
        margin: 0 0 10px 0;
        height: 44px;
        border-radius: 2px;
        background: #fcfcff;
        border: 1px solid theme-color(lightgray);
    }

    input[type="submit"] {
        display: block;
        width: 100%;
        cursor: pointer;
    }

    &__inputwrapper {
        position: relative;
        margin-bottom: 20px;
    }

    &__checkbox,
    &__radio {
        p {
            margin-bottom: 5px;
        }
        label {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 20px;
            border: 2px solid theme-color(lightblue);
            border-radius: $default-border-radius;
            margin-bottom: 0;
            cursor: pointer;
            transition: background-color 200ms ease;
            svg {
                width: 30px;
                height: 30px;
                fill: theme-color(gray);
            }
            &:hover {
                background-color: theme-color(lightblue);
                ~ .Form__userinput__actions {
                    .Button {
                        background-color: darken(theme-color(lightblue), 10%);
                    }
                }
            }
        }
        input[type="checkbox"],
        input[type="radio"] {
            &:checked {
                + label {
                    border-color: theme-color(black);
                    svg {
                        fill: theme-color(black);
                    }
                }
            }
            &:focus {
                + label {
                    outline: 2px dotted darken(theme-color(lightblue), 25%);
                }
            }
        }
    }

    input[type="checkbox"],
    input[type="radio"] {
        position: absolute;
        top: 0;
        left: 0;
        height: 1px;
        width: 1px;
        overflow: hidden;
        clip: rect(1px, 1px, 1px, 1px);
    }

    &__action {
        display: flex;
        flex-wrap: wrap;
        margin-top: 30px;
        @include mq($from: medium) {
            flex-wrap: nowrap;
        }
        input[type="submit"] {
            order: 1;
            margin-bottom: 10px;
            @include mq($from: medium) {
                order: 1;
                margin-bottom: 0;
            }
        }
    }

    &__cancel {
        display: block;
        width: 100%;
        order: 2;
        @include mq($from: medium) {
            margin-right: 20px;
            order: 1;
        }
    }

    &__addbtn {
        .Button {
            width: 100%;
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }

    &__userinput {
        > div {
            padding: 10px 20px;
            border: 2px solid theme-color(lightblue);
            border-radius: $default-border-radius;
        }
        p,
        input {
            margin: 0 !important;
        }
        input {
            padding-right: 100px;
        }
    }
    &__userinput__actions {
        position: absolute;
        right: 10px;
        top: -8px;
        display: flex;
        align-items: center;
        height: 100%;
        .Button {
            min-width: 0;
            width: 44px;
            height: 44px;
            padding: 10px;
            border-radius: 50%;
            background-color: theme-color(lightblue);
            margin-right: 5px;
            border-color: transparent;
            &:hover {
                background-color: darken(theme-color(lightblue), 5%);
                transform: none;
                &.delete {
                    background-color: util-color(error-bg);
                }
            }
            &:last-child {
                margin-right: 0;
            }
            svg {
                fill: theme-color(black);
                width: 22px;
                height: 22px;
            }
        }
        .save,
        .search {
            width: auto;
            border-radius: $default-border-radius;
            padding: 10px 20px;
            span {
                color: theme-color(black);
            }
        }
        .search {
            min-width: 150px;
        }
        &--option {
            right: 60px;
        }
    }

    textarea {
        min-height: 80px;
        line-height: 1.3;
        padding: 10px 16px;
    }

    &__errorMessage,
    &__successMessage,
    &__notifyMessage {
        border: 1px solid;
        padding: 20px 14px;
        margin-top: 20px;
        margin-bottom: 20px;
        width: 100%;
        font-weight: 600;
        p {
            display: block;
            margin: 0;
        }
    }

    &__errorMessage {
        background-color: util-color(error-bg);
        border-color: util-color(error-border);
        color: util-color(error-text);
    }

    &__successMessage {
        background-color: util-color(success-bg);
        border-color: util-color(success-border);
        color: util-color(success-text);
        text-align: center;
    }

    &__notifyMessage {
        background-color: util-color(warning-bg);
        border-color: util-color(warning-border);
        color: util-color(warning-text);
    }
}

.FormInputImage {
    max-width: 350px;
    margin: 0 auto 24px;
    @include mq($from: medium) {
        margin-bottom: 36px;
    }

    &__label {
        cursor: pointer;
        > div {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 138px;
            height: 138px;
            @include mq($from: medium) {
                width: 156px;
                height: 156px;
            }
            margin: 0 auto;
            background-color: theme-color(black);
            color: white;
            border-radius: $default-border-radius;
            transition: background-color 200ms ease;
            &:hover {
                background-color: lighten(theme-color(black), 10%);
            }
            img {
                width: 100%;
                height: 100%;
                -o-object-fit: cover;
                object-fit: cover;
                border-radius: $default-border-radius;
                &:hover {
                    filter: brightness(1.1);
                }
            }
            svg {
                width: 54px;
                height: 60px;
                fill: white;
            }
            span {
                padding: 0 2px;
                background-color: theme-color(black);
                font-size: 18px;
                font-weight: 600;
                pointer-events: none;
            }
            img + span {
                position: absolute;
                bottom: 12px;
            }
        }
    }

    input {
        top: 0;
        position: fixed;
        height: 1px;
        width: 1px;
        overflow: hidden;
        clip: rect(1px, 1px, 1px, 1px);
    }
}
