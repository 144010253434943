.Dashboard {
    display: flex;
    min-height: 100vh;

    @include mq($from: large) {
        padding-left: 300px;
    }

    &__inner {
        width: 100%;
        padding: 80px 20px 20px 20px;
        @include mq($from: large) {
            padding: 40px;
        }

        &--phone {
            padding: 80px 18px 90px 18px;
        }
    }
}
