//
// Reset
//
@mixin typography-reset-paragraphs {
    margin: 0 0 1.7rem 0;
}

//
// Default Styles
//
@mixin typography-paragraphs {
    p {
        @include typography-reset-paragraphs;
        @include build-font(paragraph);

        &.has-big-font-size {
            @include build-font(paragraph-big);
        }

        &.has-phone-font-size {
            @include build-font(paragraph-phone);
        }
    }
}
