@mixin typography-list-unstyled {
    list-style: none;
    max-width: none;
    margin: 0;
    padding: 0;
}

@mixin typography-list-inline {
    @include typography-list-unstyled;

    > li {
        display: inline-block;
        margin-bottom: 0;

        &:not(:last-child) {
            margin-right: 0.4em;
        }
    }
}

//
// Default Styles
//
@mixin typography-lists {
    ul,
    ol {
        @include build-font(paragraph);
        margin-top: 0;
        margin-bottom: 1.2rem;

        &.list-unstyled {
            @include typography-list-unstyled;
        }

        &.list-inline {
            @include typography-list-inline;
        }
    }
}
