.DrawerItem {
    display: flex;
    align-items: center;
    padding: 12px 6px;
    color: theme-color(black);

    svg {
        display: block;
        width: 24px;
        height: 24px;
        fill: theme-color(black);
        margin-right: 12px;
    }

    span {
        font-weight: 600;
    }
}
