//====================================
// RESPONSIVE BREAKPOINTS (SASS MQ)
//====================================

// Global breakpoints
$breakpoints: (
    small: 0px,
    medium: 768px,
    large: 1024px,
    xlarge: 1300px,
    xxlarge: 1700px,
) !default;

// Media Query Breakpoints
$mq-breakpoints: $breakpoints;

// Show breakpoints? Would normally inject sass variable with webpack here, depending on prod build, but requires CRA ejection
$mq-show-breakpoints: small, medium, large, xlarge, xxlarge;
// Instead showing/hiding breakpoints via class on body
body {
    &:not(.development) {
        &::before {
            content: none !important;
        }
    }
}

// Column classes for Foundation
$breakpoint-classes: (small medium large) !default;
