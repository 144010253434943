.collab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: $default-border-radius;
    width: 100%;
    padding: 18px;
    @include mq($from: medium) {
        padding: 24px;
    }
    margin-bottom: 12px;
    background-color: white;

    &__txt {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        line-height: 1.2;
        text-align: left;
        font-size: 18px;
        @include mq($from: medium) {
            font-size: 24px;
        }
        font-weight: 600;
    }

    &__img {
        width: 84px;
        height: 72px;
        margin-left: 6px;
        @include mq($from: medium) {
            width: 144px;
            height: 120px;
            margin-left: 12px;
        }
        object-fit: contain;
    }
}
