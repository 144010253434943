.profile-wrapper {
    display: flex;
    justify-content: space-between;
    background-color: white;

    @include mq($until: medium) {
        flex-wrap: wrap;
    }

    h3 {
        margin-right: 24px;
        min-width: 192px;
    }
}

.profile-form-wrapper {
    width: 100%;

    @include mq($from: medium) {
        max-width: 400px;
    }
}

.profile {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile__title {
    align-self: flex-start;
    margin-bottom: 30px;
}

.profile__img {
    width: 138px;
    height: 138px;
    border-radius: 69px;
    margin-bottom: 6px;
}

.profile__form {
    display: flex;
    flex-direction: column;

    &--phone {
        width: 100%;
        max-width: 400px;
    }
}

.profile__form__input {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: theme-color(black);
    margin-bottom: 12px;
    border-radius: $default-border-radius;
    padding: 15px 30px;
    background-color: theme-color(lightblue);
    border: 1px solid theme-color(lightblue);
    -moz-appearance: textfield;

    &::placeholder {
        color: theme-color(gray);
    }

    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &:focus {
        border: 1px solid #1c1c1c;
        background-color: white;
    }

    &:placeholder-shown {
        border: 1px solid theme-color(lightgray);
        background-color: white;
    }
}

.profile__form__select {
    padding-right: 6px;
    -moz-appearance: none;
    -webkit-appearance: none;

    &--empty {
        border: 1px solid theme-color(lightgray);
        background-color: white;
        color: theme-color(gray);
    }
}
