.intro {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    background-color: white;

    @include mq($until: medium) {
        flex-wrap: wrap;
    }
}

.intro__left {
    @include mq($until: medium) {
        width: 100%;

        h1 {
            margin-bottom: 6px;
        }
    }
}

.intro__img-wrapper {
    position: relative;
    width: 120px;
    height: 120px;
    border-radius: 60px;

    @include mq($until: medium) {
        margin: 0 auto;
    }

    @include mq($from: medium) {
        width: 142px;
        height: 142px;
        border-radius: 71px;
    }

    > button {
        position: absolute;
        right: 0;
        top: 0;
        padding: 0;

        @include mq($until: medium) {
            right: -6px;
            top: -6px;
        }

        width: 36px;
        height: 36px;

        background-color: theme-color(black);
        border-radius: 18px;

        > svg {
            width: 24px;
            height: 24px;
            fill: white;
        }

        transition: background-color 0.2s linear, transform 0.2s ease;

        &:hover {
            background-color: lighten(theme-color(black), 5%);
            transform: translateY(-1px);
        }

        &:active {
            transform: translateY(1px);
        }

        &:focus {
            outline: 2px dotted darken(theme-color(lightblue), 25%);
        }
    }
}

.intro__img {
    border-radius: 60px;

    @include mq($from: medium) {
        border-radius: 71px;
    }
}

.intro__img--placeholder {
    background-color: theme-color(lightblue);
    padding: 30px;
    width: 100%;
    height: 100%;
}

.profile-photo-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    button {
        margin: 8px 0;
        width: 100%;
        max-width: 360px;

        &:first-of-type {
            margin-top: 24px;
            @include mq($until: medium) {
                margin-top: 84px;
            }
        }
        &:last-of-type {
            margin-top: 30px;
            @include mq($until: medium) {
                margin: auto 0 30px;
            }
        }
    }
}
