.Sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 250px;
    height: 100vh;
    background-color: theme-color(white);
    padding: 20px;
    transform: translate3d(-250px, 0, 0);
    visibility: hidden;
    z-index: 9;
    transition: transform 0.3s cubic-bezier(0.4, 0, 1, 1), visibility 0s ease 0.3s;

    @include mq($from: medium) {
        width: 300px;
        transform: translate3d(-300px, 0, 0);
    }

    @include mq($from: large) {
        transform: none;
        visibility: visible;
    }

    &--open {
        transform: translate3d(0, 0, 0);
        visibility: visible;
        transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1);
    }

    &__bottom {
        margin-top: auto;
    }

    > .Logo {
        margin-bottom: 18px;
        font-size: 36px;
        @include mq($from: medium) {
            font-size: 48px;
        }
    }
}
