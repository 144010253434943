.TabBar {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 66px;
    padding-top: 3px;
    background-color: theme-color(lightblue);
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    border-top: 1px solid lightgray;
    z-index: 1;
}

.TabBarItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgb(55%, 66%, 71%);
    width: 72px;

    &.current-page {
        color: black;

        svg {
            fill: black;
        }
    }

    svg {
        width: 28px;
        height: 28px;
        fill: rgb(55%, 66%, 71%);
        margin-bottom: 3px;
    }

    span {
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 3px;
    }
}
