.Topic {
    &__nav {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        max-width: 600px;
        margin: 0 auto;

        &--prev,
        &--next {
            min-width: 0;
        }
        &--prev {
            width: 100%;
            order: 2;
            @include mq($from: medium) {
                width: calc(35% - 10px);
                order: 1;
            }
            span {
                margin-left: 8px;
                margin-right: 0;
            }
        }
        &--next {
            order: 1;
            width: 100%;
            margin-bottom: 20px;
            @include mq($from: medium) {
                width: calc(65% - 10px);
                order: 2;
                margin-bottom: 0;
            }
        }
    }
}

.topic-card-wrapper {
    @include mq($from: xlarge) {
        width: 50%;
    }
}

.TopicCard {
    display: flex;
    align-items: center;

    h3 {
        &.h3--phone {
            font-size: 23px;
            margin-bottom: 6px;
        }
    }
    &__indicator {
        display: inline-block;
        padding: 12px 20px;
        @include build-font("paragraph-small");
        font-weight: 600;
        line-height: 1;
    }
    &__indicator--phone {
        display: inline-block;
        height: 28px;
        padding: 6px 12px;
        border-radius: 14px;
        font-size: 12px;
        font-weight: 600;
    }
    &__content {
        margin-right: 20px;
    }
    &__illustration {
        margin-left: auto;
        width: 80px;
        min-width: 80px;
        @include mq($from: large) {
            @include mq($until: xlarge) {
                width: 60px;
                min-width: 60px;
            }
        }
        @include mq($from: xlarge) {
            width: auto;
        }
        @include mq($from: xxlarge) {
            margin-right: 40px;
        }
    }
}
