.ProgressRing {
    position: absolute;
    top: 20px;
    right: 20px;
    display: block;
    height: 80px;
    width: 80px;
    span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: theme-color(black);
        @include build-font("paragraph-small");
        font-weight: bold;
        opacity: 0.8;
    }
}
