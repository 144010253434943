.mdc-dialog {
    z-index: 1000;
}

.la-form {
    display: flex;
    flex-direction: column;

    height: 100%;
}

.la-form,
.profile__form,
.la-modal__form {
    .mdc-text-field,
    .mdc-select {
        .mdc-notched-outline > div {
            border-color: theme-color(grey) !important;
        }
        .mdc-floating-label {
            color: rgba(0, 0, 0, 0.6);
        }
        .mdc-text-field__input {
            caret-color: theme-color(black);
        }

        &:hover:not(.mdc-text-field--invalid) {
            .mdc-notched-outline > div {
                border-color: theme-color(black) !important;
            }
        }
    }

    .mdc-text-field--invalid {
        .mdc-notched-outline > div {
            border-color: theme-color(red) !important;
        }
        .mdc-floating-label {
            color: theme-color(red);
        }
        .mdc-text-field__input {
            caret-color: theme-color(red);
        }
    }

    .mdc-floating-label,
    .mdc-text-field-helper-text {
        font-family: $theme-font-family;
        line-height: 1.19rem;
        font-weight: 600;
    }

    .mdc-text-field__input,
    .mdc-select__selected-text {
        font-family: $theme-font-family;
        font-size: 18px;
    }

    .mdc-notched-outline__notch {
        line-height: 1.3;
    }

    .mdc-select:not(.mdc-select--disabled).mdc-select--focused .mdc-floating-label {
        color: rgba(0, 0, 0, 0.6);
    }

    .mdc-select--focused .mdc-select__dropdown-icon {
        background: url(data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%23000%22%20fill-rule%3D%22evenodd%22%20opacity%3D%220.54%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E)
            no-repeat center;
    }
}

.la-form__field {
    margin-bottom: 24px;

    > * {
        width: 100%;
    }

    .mdc-select__anchor {
        width: 100%;
    }
}
