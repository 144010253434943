.Link__pending {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 100%;

    .la-fab {
        position: absolute;
        top: 0;
        right: 0;
    }

    &__img {
        width: 108px;
        height: 108px;
        @include mq($from: medium) {
            width: 150px;
            height: 150px;
        }
        margin: 0 auto 10px auto;
        background-color: theme-color(lightblue);
        overflow: hidden;
        border-radius: 50%;
        svg {
            display: block;
            width: 100%;
            height: 100%;
            padding: 24px;
        }
    }

    &__text {
        max-width: 400px;
        p {
            &:first-child {
                margin-bottom: 0;
            }
            &:last-child {
                margin-bottom: 20px;
            }
        }
    }

    &__actions {
        display: flex;
        flex-direction: column;
        margin-top: auto;
        .Button {
            &:first-child {
                margin-bottom: 10px;
            }
        }
    }
}

.link-sharing-card-wrapper {
    @include mq($from: xlarge) {
        width: 50%;
    }
}

.link-sharing-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: $default-border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

    &__title {
        font-size: 1.3rem;
        font-weight: 600;
        margin-bottom: 18px;
        text-align: center;
        @include mq($until: medium) {
            max-width: 280px;
            margin: 0 auto 18px;
        }
    }
    p {
        margin-bottom: 0 !important;
        line-height: 1.45;
    }
    .Button {
        width: 210px;
        @include mq($from: xlarge) {
            width: 100%;
        }
        @include mq($until: medium) {
            width: 100%;
        }
    }
    .btn--delete:hover {
        color: theme-color(black);
        background-color: theme-color(red);
        border-color: theme-color(red);
        > svg {
            fill: theme-color(black);
        }
    }

    &.bg-lightblue {
        border: 2px solid white;
    }
}

.link-preview-wrapper {
    > button {
        width: 100%;
    }
    @include mq($from: xlarge) {
        width: 50%;
    }
}

.link-preview {
    p {
        text-align: left;
        color: theme-color(black);
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div:first-child {
        text-align: left;
        p {
            margin-bottom: 0;
            font-weight: 600;
            font-size: 18px;
            line-height: 1.2;
            @include mq($from: medium) {
                font-size: 24px;
            }
        }
    }
    &__status {
        display: inline-flex;
        align-items: center;
        padding: 8px 20px;
        background-color: transparentize(theme-color(white), 0.4);
        border-radius: 23.5px;
        margin-top: 6px;
        @include mq($until: medium) {
            padding: 4.5px 12px;
            border-radius: 15px;
        }
        > span {
            white-space: nowrap;
            margin-left: 12px;
            color: theme-color(black);
            @include mq($until: medium) {
                margin-left: 6px;
                font-size: 12px;
            }
        }
        > svg {
            width: 18px;
            min-width: 18px;
            height: 18px;
            @include mq($from: medium) {
                width: 30px;
                min-width: 30px;
                height: 30px;
            }
        }
    }
    .link__img {
        width: 72px;
        min-width: 72px;
        height: 72px;
        border-radius: 50%;
        background-color: white;
        color: theme-color(black);
        margin-left: 6px;
        @include mq($from: medium) {
            width: 150px;
            height: 150px;
        }
    }
    h1.link__img {
        margin-bottom: 0;
        text-align: center;
        font-size: 49px;
        line-height: 1.45;
        @include mq($from: medium) {
            font-size: 84px;
            line-height: 1.75;
        }
    }
    svg.link__img {
        overflow: visible;
        padding: 18px;
        @include mq($from: medium) {
            padding: 24px;
        }
    }

    &--new p {
        color: white;
    }
}
