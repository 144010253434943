.so-me-card-wrapper {
    display: flex;
    flex-direction: column;
}

.so-me-card {
    display: flex;
    align-items: center;

    > img {
        width: 42px;
        margin-right: 18px;
        @include mq($from: medium) {
            width: 60px;
        }
        @include mq($from: large) {
            width: 78px;
            margin-right: 24px;
        }
    }

    > p {
        font-size: 18px;
        @include mq($from: medium) {
            font-size: 24px;
        }
        font-weight: 600;
        color: theme-color(black);
        margin: 0;
    }

    > svg {
        margin-left: auto;
        color: theme-color(black);
    }
}
