.legal {
    max-width: 800px;
    margin: 0 auto;

    h1,
    h2,
    h3 {
        text-align: center;
    }

    .la-fab {
        margin-bottom: 18px;
    }

    .guide__more-btn {
        display: block;
        margin: 18px auto;
    }

    .cta {
        max-width: 495px;
        margin: 36px auto 42px;

        @include mq($from: medium) {
            background-color: white;
            border-radius: $default-border-radius;
            padding: 24px;
            max-width: calc(495px + 48px);
            margin: 36px auto 54px;
            box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05), 0 15px 40px rgba(166, 173, 201, 0.2);
        }

        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        font-weight: 600;
        white-space: pre-line;
        @include mq($from: medium) {
            background-color: theme-color(lightblue);
            border: 2px solid white;
        }
        button:last-of-type {
            margin-top: 6px;
        }
    }

    > p {
        white-space: pre-line;

        &:last-of-type {
            text-align: center;
            margin-top: 18px;
        }
    }
}

.legal-card {
    &__title-row {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__title {
            margin: 0;
            color: theme-color(black);
            text-align: start;
        }

        > svg {
            font-size: 1.5rem;
            width: 1em;
            height: 1em;
            color: theme-color(black);
            margin-left: 8px;
            @include mq($from: medium) {
                font-size: 2.25rem;
                width: 1em;
                height: 1em;
            }
        }
    }

    > p {
        font-size: 18px;
        text-align: left;
        color: theme-color(black);
        @include mq($from: medium) {
            font-size: 24px;
        }
        margin: 0;
    }
}
