.charity-donation {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    background-color: white;
    margin-bottom: 60px;

    .la-form__field {
        width: 100%;
        max-width: 400px;
        margin: 0 auto 24px;

        > button {
            margin-top: 12px;
        }

        .btn--delete {
            margin-top: 0;
            &:hover {
                color: theme-color(black);
                background-color: theme-color(red);
                border-color: theme-color(red);
            }
        }
    }
}
