$theme-colors: (
    red: #f7867d,
    pink: #eeb5a7,
    purple: #c9bfe2,
    yellow: #ffedca,
    green: #d0e3be,
    orange: #eccaa3,
    lightblue: #e5edee,
    lightgray: #e9e9e9,
    lightergray: #f7f7f7,
    blue: rgb(184, 226, 255),
    grey: #a1a1a1,
    black: #1c1c1c,
    white: #fff,
) !default;

$util-colors: (
    warning-text: #856404,
    warning-bg: #fff3cd,
    warning-border: #ffeeba,
    success-text: #155724,
    success-bg: #d4edda,
    success-border: #c3e6cb,
    error-text: #721c24,
    error-bg: #f8d7da,
    error-border: #f5c6cb,
) !default;
