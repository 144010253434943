//
// Reset
//
@mixin typography-reset-buttons {
    color: inherit;
    text-decoration: none;
}

//
// Default Styles
//
@mixin typography-buttons {
    button {
        font-family: $theme-font-family;
        cursor: pointer;
    }
}

// Standard button
.Button {
    display: inline-block;
    padding: 16px 18px;
    min-width: 150px;

    // Text
    text-align: center;
    @include build-font("paragraph");
    line-height: 1;
    font-weight: 600;

    // Standard btn colors
    background-color: theme-color(black);
    color: theme-color(white);

    // Border
    border: 2px solid theme-color(black);
    border-radius: $default-border-radius;

    // Reset
    outline: 0;
    text-decoration: none;
    cursor: pointer;

    // Transition
    transition: background-color 0.2s linear, border-color 0.2s linear, transform 0.2s ease;

    &:hover {
        background-color: lighten(theme-color(black), 5%);
        border-color: lighten(theme-color(black), 5%);
        transform: translateY(-1px);
    }

    &:active {
        transform: translateY(1px);
    }

    &:focus {
        outline: 2px dotted darken(theme-color(lightblue), 25%);
    }

    &.disabled,
    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &--loading {
        opacity: 0.8;
        cursor: progress;
    }
    &--loading-inline {
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.8;
        pointer-events: none;
    }
}

// Button with icon
.Button--icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 12px 18px;
    span {
        display: block;
        margin-right: 8px;
    }
    svg:not(.MuiSvgIcon-root) {
        display: block;
        width: 30px;
        min-width: 30px;
        height: 30px;
        fill: theme-color(white);
        transition: fill 0.2s ease;
    }
}

.Button--outline {
    background-color: transparent;
    color: theme-color(black);
    svg {
        fill: theme-color(black);
    }
    &:hover {
        color: theme-color(white);
        background-color: theme-color(black);
        svg {
            fill: theme-color(white);
        }
    }
}

.Button--red {
    height: 60px;
    background-color: theme-color(red);
    border-color: theme-color(red);
    color: theme-color(black);
    &:hover {
        background-color: darken(theme-color(red), 5%);
        border-color: darken(theme-color(red), 5%);
    }

    svg {
        fill: theme-color(black);
    }
}

.Button--purple {
    height: 60px;
    background-color: theme-color(purple);
    border-color: theme-color(purple);
    color: theme-color(black);
    &:hover {
        background-color: lighten(theme-color(purple), 2%);
        border-color: lighten(theme-color(purple), 2%);
    }

    svg {
        fill: theme-color(black);
    }
}

.Button--green {
    height: 60px;
    background-color: theme-color(green);
    border-color: theme-color(green);
    color: theme-color(black);
    &:hover {
        background-color: darken(theme-color(green), 5%);
        border-color: darken(theme-color(green), 5%);
    }
}
a.Button--green {
    padding: 19px;
}

.Button--floating {
    box-shadow: rgba(149, 157, 165, 0.22) 0 8px 24px;
    transition: background-color 0.2s linear, border-color 0.2s linear, transform 0.2s ease, box-shadow 0.2s linear;

    &:hover {
        box-shadow: rgba(149, 157, 165, 0.23) 0 12px 24px;
    }
}

.Button--flat {
    background-color: transparent;
    border: none;
    color: theme-color(black);
    &:hover {
        background-color: lighten(theme-color(black), 80%);
    }
}

.la-fab {
    background-color: theme-color(black);
    color: white;
    border-radius: $default-border-radius;
    width: 60px;
    min-width: 60px;
    height: 60px;
    min-height: 60px;

    > svg {
        transform: translate(1px, 2px);
    }

    transition: background-color 0.2s linear, border-color 0.2s linear, transform 0.2s ease;

    &:hover {
        background-color: lighten(theme-color(black), 5%);
        transform: translateY(-1px);
    }

    &:active {
        transform: translateY(1px);
    }

    &:focus {
        outline: 2px dotted darken(theme-color(lightblue), 25%);
    }

    &--disabled {
        pointer-events: none;
        background-color: theme-color(lightgray);
    }

    &--white {
        background-color: transparent;
        color: theme-color(black);
        &:hover {
            background-color: lighten(theme-color(black), 5%);
            color: white;
        }
    }
}

a.la-fab {
    text-align: center;
    line-height: 4.4;
    cursor: pointer;
}
