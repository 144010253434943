.Logo {
    font-family: "DM Serif Display", serif;
    text-align: center;
    font-size: 60px;
    line-height: 2;
    margin: 0 auto;
    @include mq($from: medium) {
        font-size: 78px;
    }
}
