.Header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    background-color: theme-color(white);
    border-bottom: 1px solid #e0e2e4;
    z-index: 8;

    @include mq($from: large) {
        display: none;
    }

    > div:last-child {
        width: 45px;
        height: 45px;
    }

    &__burger {
        width: 45px;
        height: 45px;
        padding: 0;
        svg {
            display: block;
            width: 35px;
            height: 35px;
            margin: 0 auto;
        }

        &--phone {
            svg {
                width: 24px;
                height: 24px;
            }
        }
    }

    .Logo {
        font-size: 30px;
    }

    &__scrim {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        height: 100vh;
        z-index: 8;
        opacity: 0;
        visibility: hidden;
        background: rgba(0, 0, 0, 0.4);
        cursor: pointer;
        pointer-events: none;
        transition: opacity 0.3s ease, visibility 0s ease 0.3s;
    }

    &.Sidebar--open {
        .Header__scrim {
            pointer-events: auto;
            opacity: 1;
            visibility: visible;
            transition: opacity 0.3s ease;
        }
    }
}
