.alert,
.prompt {
    .MuiDialog-paper {
        padding: 24px 18px;
        @include mq($from: medium) {
            padding: 24px;
        }
        height: unset;
    }

    @include mq($until: medium) {
        text-align: center;
    }

    &__body {
        padding: 18px;
    }

    &__action {
        display: flex;
        margin-top: auto;
        @include mq($until: medium) {
            flex-wrap: wrap;
            justify-content: center;
            button {
                width: 100%;
                &:first-of-type {
                    margin-bottom: 12px;
                }
            }
        }
        @include mq($from: medium) {
            flex-direction: row-reverse;
        }
    }
}

.prompt {
    &__action {
        @include mq($until: medium) {
            button {
                margin-bottom: 12px;
                &:last-of-type {
                    margin: 12px 0 0;
                }
            }
        }
        @include mq($from: medium) {
            button {
                margin-left: 12px;
                &:last-of-type {
                    margin: 0 12px 0 0;
                }
            }
        }
    }
}
