.questionnaire-question,
.questionnaire-result {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
}

.questionnaire-question {
    max-width: 400px;

    &__title {
        text-align: center;
        margin: 30px 0 48px;
        @include mq($until: medium) {
            margin: 18px 0 30px;
        }
    }
}

.questionnaire-answer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #e9e9e9;
    border-radius: $default-border-radius;
    background-color: white;
    width: 100%;
    padding: 12px;
    @include mq($from: medium) {
        padding: 18px;
    }
    margin-bottom: 18px;
    cursor: pointer;
    transition: border-color 200ms linear, background-color 200ms linear;

    &:hover {
        border-color: theme-color(black);
    }

    &--selected {
        border-color: theme-color(black);
        background-color: theme-color(lightblue);
    }

    > label {
        font-size: 18px;
        @include mq($from: medium) {
            font-size: 1.5rem;
        }
        pointer-events: none;
    }

    > svg {
        width: 30px;
        height: 30px;
    }
}

.questionnaire-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 18px;

    &__links {
        .la-fab {
            display: inline-block;
            margin-left: 6px;
        }
    }
}

.questionnaire-result {
    max-width: 600px;

    &__title {
        text-align: center;
        margin: 30px 0;
        @include mq($until: medium) {
            margin-top: 18px;
        }
    }

    &__inline-btn {
        cursor: pointer;
        font-weight: bold;
        background-color: theme-color(purple);
    }

    > img {
        box-sizing: content-box;
    }

    > img,
    .cta {
        max-width: 495px;
        margin: 6px 0 42px;

        @include mq($from: medium) {
            background-color: white;
            border-radius: $default-border-radius;
            padding: 24px;
            max-width: calc(495px + 48px);
            margin: 0 0 54px;
            box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05), 0 15px 40px rgba(166, 173, 201, 0.2);
        }
    }

    .cta {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        margin-top: 18px;
        font-weight: 600;
        white-space: pre-line;
        @include mq($from: medium) {
            background-color: theme-color(lightblue);
            border: 2px solid white;
        }
        button:last-of-type {
            margin-top: 6px;
        }
    }

    > p {
        white-space: pre-line;

        &:last-of-type {
            text-align: center;
            margin-top: 18px;
        }
    }

    > button {
        & + button {
            margin: 12px 0 24px;
        }
    }

    &__disclaimer {
        text-align: center;
        font-size: 14px;
        opacity: 0.8;
        @include mq($until: medium) {
            font-size: 12px;
        }
    }
}

.questionnaire-route {
    padding: 84px 18px 18px;
    &__item {
        text-align: center;

        > p {
            margin-bottom: 0;
        }
    }

    > button {
        position: absolute;
        top: 18px;
        right: 18px;
    }

    &__result-id {
        position: absolute;
        top: 12px;
        left: 18px;
        color: white;
    }
}

.questionnaire-contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 36px 24px 24px;

    > img {
        margin: 84px 0 30px;
    }

    > p {
        margin-top: 18px;
        text-align: center;
    }

    .la-form {
        .or {
            font-size: 12px;
            font-weight: 600;
            color: dimgray;
            letter-spacing: 1.6px;
            margin: 6px 0 0;
        }

        > .Button {
            margin: 36px 0 24px;
        }
    }

    > .la-fab {
        position: absolute;
        top: 18px;
        right: 18px;
    }
}
