//====================================
// Grid settings (zurb foundation)
//====================================

.grid-container {
    max-width: $grid-container;
    margin: 0 auto;
    width: 100%;
}

.grid-padding-x--default {
    @include xy-gutters($gutters: $grid-padding-gutters-default, $negative: true);

    // Base cell styles
    > .cell {
        @include xy-gutters($gutters: $grid-padding-gutters-default, $gutter-type: padding);
    }
}

.grid-padding-x--small {
    // Negative margin for grids within `grid-container/grid-container.fluid`
    // This allows margin and padding grids to line up with eachother
    @include xy-gutters($gutters: $grid-padding-gutters-small, $negative: true);

    // Base cell styles
    > .cell {
        @include xy-gutters($gutters: $grid-padding-gutters-small, $gutter-type: padding);
    }
}

.grid-padding-x--big {
    // Negative margin for grids within `grid-container/grid-container.fluid`
    // This allows margin and padding grids to line up with eachother
    @include xy-gutters($gutters: $grid-padding-gutters-big, $negative: true);

    // Base cell styles
    > .cell {
        @include xy-gutters($gutters: $grid-padding-gutters-big, $gutter-type: padding);
    }
}

.grid-padding-y-cells {
    @each $breakpoint, $gutter in $grid-padding-gutters {
        > .cell {
            @include mq($from: $breakpoint) {
                padding-bottom: $gutter;
            }
        }
    }
}
