ul.QuestionList {
    max-width: 600px;
    margin: 40px auto 0 auto;
}

.Question {
    &__title {
        line-height: 1;
        margin-bottom: 0;
    }
    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: theme-color(lightblue);
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
        svg {
            width: 40px;
            height: 40px;
            margin-left: 10px;
        }
    }
    &__desc {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
        p {
            margin-bottom: 16px;
        }
        p:last-child {
            margin-bottom: 0;
        }
        > button {
            padding: 0 42px;
        }
    }
    &__answer {
        background-color: theme-color(white);
        p:last-child {
            margin-bottom: 0;
        }
        ul {
            padding-left: 20px;
            list-style-type: circle;
            margin-bottom: 20px;
        }
    }
    &__answer__title {
        line-height: 1;
        margin-bottom: 10px;
    }
    &__answer__comment {
        margin-top: 10px;
        span {
            display: block;
            font-family: $theme-font-family-headlines;
            font-size: rem-calc(20px);
        }
    }
    &__editbtn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: theme-color(black);
        padding: 20px 40px;
        border: 2px solid theme-color(black);
        border-bottom-left-radius: inherit;
        border-bottom-right-radius: inherit;
        span {
            @include build-font("paragraph");
            color: theme-color(white);
            line-height: 1;
            font-weight: 600;
        }
        svg {
            width: 30px;
            height: 30px;
            margin-left: 10px;
            fill: theme-color(white);
        }
        &:hover {
            background-color: lighten(theme-color(black), 5%);
            border-color: lighten(theme-color(black), 5%);
        }
    }
    &--completed {
        .Question__top {
            background-color: theme-color(green);
        }
    }
    &__dots {
        margin: 12px 0;
        span {
            display: block;
            text-align: center;
            line-height: 1;
            font-size: rem-calc(20px);
        }
    }
}

.QuestionForm {
    &__actions {
        margin-top: 30px;
        .Button {
            width: 100%;
        }
        input[type="submit"] {
            margin-top: 10px;
        }
    }
}
