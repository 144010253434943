.heirloom {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__top {
        position: relative;
        height: 100px;
        flex-basis: 36%;
        @include mq($from: medium) {
            flex-basis: 48%;
        }
        margin: -20px -20px 0;
        @include mq($from: large) {
            margin: -40px -40px 0;
        }

        img.heirloom__img {
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
            -o-object-position: 50% 50%;
            object-position: 50% 50%;
        }

        svg.heirloom__img {
            display: block;
            width: 120px;
            height: 100%;
            margin: auto;
        }

        > .la-fab {
            position: absolute;
            top: 12px;
            left: 12px;
        }
    }

    &__bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100px;
        flex-basis: 64%;
        @include mq($from: medium) {
            flex-basis: 52%;
        }

        > p {
            text-align: center;
            opacity: 0.5;
            margin-bottom: 0;
        }

        .heirloom__title {
            margin: 12px 0;
            padding: 0 18px;
            text-align: center;
        }
        .heirloom__value {
            margin: 0 0 18px;
            padding: 0 18px;
            text-align: center;
        }

        .heirloom__link {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px;
            margin: 12px 0;
            border: 1px solid theme-color(lightgrey);
            min-width: 300px;
            width: 100%;
            @include mq($from: medium) {
                min-width: 400px;
                max-width: 600px;
            }

            @include mq($from: medium) {
                padding: 12px 18px;
                margin-bottom: 18px;
            }

            &__img {
                width: 66px;
                min-width: 66px;
                height: 66px;
                margin-left: 12px;
                border-radius: 50%;
                @include mq($from: medium) {
                    width: 102px;
                    min-width: 102px;
                    height: 102px;
                }
                background-color: theme-color(lightblue);
            }
            h1.heirloom__link__img {
                margin: 0;
                text-align: center;
                font-size: 48px;
                line-height: 1.37;
                @include mq($from: medium) {
                    font-size: 66px;
                    line-height: 1.55;
                }
            }

            p {
                margin-bottom: 0;
                font-weight: 600;
                font-size: 18px;
                @include mq($from: medium) {
                    font-size: 24px;
                }
            }
        }

        .heirloom__edit-btn {
            margin: auto 0 42px;
        }

        #delete-heirloom-btn {
            border-color: white;
            &:hover,
            &:focus {
                background-color: theme-color(red);
                color: theme-color(black);
                border-color: theme-color(red);
            }
        }
    }
}

.heirloom-preview {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: $default-border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease-in-out;

    &__image {
        border-top-left-radius: $default-border-radius;
        border-top-right-radius: $default-border-radius;
        width: 100%;
        height: 200px;
        background-color: theme-color(lightgray);
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: 50% 50%;
        object-position: 50% 50%;
    }
    &__title {
        line-height: 1;
        padding: 12px;
        margin: 0;
        text-align: center;
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        opacity: 0;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
        transition: opacity 0.3s ease-in-out;
        border-radius: inherit;
    }

    &:hover {
        transform: translateY(-5px);
        &::after {
            opacity: 1;
        }
    }
}

.heirloom-new {
    svg {
        margin-top: 12px;
        width: 50px;
        height: 50px;
        background-color: theme-color(lightblue);
    }

    .heirloom-preview__title {
        padding: 6px 12px 24px;
    }
}

.heirloom-form {
    display: flex;
    flex-direction: column;

    .la-modal__actions {
        @include mq($until: medium) {
            flex-wrap: wrap;
            width: 100%;
            > button {
                width: 100%;
            }

            > .Button--flat {
                margin-bottom: 12px;
            }
        }
    }
}

.nav-bar {
    display: flex;
    align-items: center;
    height: 60px;
    margin-bottom: 30px;

    .la-fab {
        position: absolute;
    }

    h1,
    h2,
    h3 {
        margin: 0 0 0 auto;
        @include mq($from: medium) {
            margin: 0 auto;
        }
    }
}
