.Tabs {
    width: 100%;
    max-width: 800px;
    margin-top: 6px;

    ul {
        display: flex;
        li {
            width: 100%;
            padding: 0 12px;
        }
        button {
            display: block;
            width: 100%;
            padding: 6px;
            font-size: 18px;
            font-weight: 600;
            color: dimgray;
            border-bottom: 3px solid transparent;
            transition: border-bottom-color, color 0.1s linear;
            &[data-active="true"] {
                border-bottom-color: theme-color(black);
                color: theme-color(black);
            }
        }
    }
}

.tabs {
    display: flex;
    justify-content: space-between;
    width: calc(100% + 24px);
    max-width: 800px;
    margin-top: 12px;
    margin-bottom: 12px;
    margin-left: -12px;

    > .tab {
        width: 100%;
        text-align: center;
        padding: 5px 0 3px;
        margin: 0 6px;
        font-size: 18px;
        font-weight: 600;
        color: dimgray;
        border-bottom: 3px solid transparent;

        @include mq($from: medium) {
            font-size: 24px;
        }

        &--active {
            border-bottom-color: theme-color(black);
            color: theme-color(black);
        }
    }

    @include mq($from: medium) {
        margin-bottom: 18px;
    }
}
