.Login,
.SignUp {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;

    @include mq($from: medium) {
        justify-content: center;
    }

    .Logo {
        margin-bottom: 12px;
    }
    h1 {
        @include build-font("headline-2");
    }
    &__illustration {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 1;
        filter: brightness(120%);
        opacity: 0.5;
    }
    .Logo,
    &__form {
        position: relative;
        z-index: 2;
    }
    &__form {
        width: 90%;
        padding: 18px 24px;
        margin-bottom: 18px;
        box-shadow: 0 15px 75px rgba(139, 140, 185, 0.25);
        @include mq($from: medium) {
            width: 600px;
            padding: 50px 50px 40px 50px;
        }
    }
    &__alternate {
        margin-bottom: 0;
        text-align: center;
        line-height: 1;
        &:first-child {
            margin-top: 12px;
            margin-bottom: 12px;

            @include mq($from: medium) {
                margin-top: 24px;
                margin-bottom: 18px;
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.Login,
.SignUp {
    min-height: 100vh;
    background-color: theme-color(lightblue);
    background-image: url(https://storage.googleapis.com/adjo_public/backgrounds/adjo-bag-app.png);
    background-blend-mode: soft-light;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;

    &__form--phone {
        margin-bottom: 54px;
    }
}

.SignUp__form {
    margin-bottom: 54px;
}

form {
    .Button {
        width: 100%;
        margin-top: 16px;
    }

    input[type="password"]::-ms-reveal,
    input[type="password"]::-ms-clear {
        display: none;
    }
}

.stores {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    margin: 0 12px 0;
    @include mq($from: medium) {
        margin-bottom: 100px;
    }
}

.store {
    display: inline-block;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    height: 45px;
    @include mq($from: medium) {
        height: 50px;
        margin: 6px 12px;
    }

    &--apple {
        background-image: url(https://storage.googleapis.com/adjo_public/badges/app_store.svg);
        width: 149px;
    }
    &--google {
        background-image: url(https://storage.googleapis.com/adjo_public/badges/play_store_cropped.png);
        width: 167px;
    }
}

.copyright-footer {
    position: absolute;
    bottom: 12px;
}
