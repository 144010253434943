.q11e-wrapper {
    transition: opacity 0.3s linear;

    &--fade-out {
        opacity: 0;
    }
}

.q11e-nav {
    display: flex;

    &--start {
        justify-content: flex-end;
        @include mq($until: medium) {
            justify-content: center;
        }

        > .Button {
            margin: 0 8px;
            width: 304px;
            @include mq($until: medium) {
                width: 100%;
            }
        }
    }

    &__nxt-btn {
        margin-left: auto;
        width: 320px;
        @include mq($until: medium) {
            width: 100%;
        }
    }
}

.q11e-form {
    @include mq($from: medium) {
        padding: 18px;
    }

    &__help-txt {
        padding: 18px 13px 6px;
        @include mq($from: medium) {
            padding: 18px 19px 6px;
        }

        &__title {
            text-transform: uppercase;
            font-size: 1.25rem;
            @include mq($from: medium) {
                font-size: 1.5rem;
            }
            font-weight: 600;
            margin-bottom: 0;

            &--optional::after {
                content: "valgfri";
                font-size: 1rem;
                text-transform: none;
                margin-left: 6px;
                color: theme-color(red);
            }
        }

        &__body {
            @include build-font(paragraph-big);
            @include mq($until: medium) {
                @include build-font(paragraph-phone);
            }
            margin-bottom: 6px;
            max-width: 800px;
        }
    }

    .q11e-nav {
        margin-top: 18px;
    }
}

.q11e-storefront {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: $default-border-radius;
    box-shadow: 0 5px 12px 6px rgba(0, 0, 0, 0.05);
    text-align: center;

    &__txt {
        @include build-font(paragraph-big);
        @include mq($until: medium) {
            @include build-font(paragraph-phone);
        }
        max-width: 800px;
        white-space: pre-line;
        margin-bottom: 12px;

        &--amount {
            font-weight: 600;
        }

        > .guide__more-btn {
            font-size: inherit;
        }
    }

    > .la-form {
        margin-top: 12px;
    }

    &__checkbox {
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        &:last-of-type {
            margin-bottom: 30px;
        }

        * {
            cursor: pointer;
        }

        > input {
            margin-right: 12px;
        }

        > label {
            max-width: 420px;
            text-align: left;

            > button {
                color: theme-color(black);
                text-decoration-line: underline;
                text-decoration-color: theme-color(purple);
                text-decoration-thickness: 3px;
                padding: 0;
            }
        }
    }

    &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 300px;
        position: relative;

        .button-enumeration {
            position: absolute;
            left: 12px;
        }
    }

    &__help {
        margin-top: 30px;
        font-style: italic;

        &__more-btn {
            text-decoration-line: underline;
            text-decoration-style: solid;
            text-decoration-color: #c9bfe2;
            text-decoration-thickness: 5px;
        }
    }

    &__voucher-input {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 24px;

        > p {
            width: 100%;
        }

        > input[type="text"] {
            width: 48%;
            @include mq($from: medium) {
                width: 49%;
            }
        }

        > button {
            margin-top: 0;
            padding: 19px 18px;
            min-width: 108px;
            height: 60px;
            width: 48%;
            @include mq($from: medium) {
                width: 49%;
            }
        }
    }

    &__dots {
        margin: 3px 0;

        span {
            display: block;
            line-height: 1;
            font-size: rem-calc(20px);
        }
    }
}

.q11e-result {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: $default-border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

    &__item {
        text-align: center;
        margin-bottom: 36px;
        width: 100%;
        max-width: 600px;

        &:not(&--last) {
            border-bottom: 1px solid theme-color(lightgrey);
        }
    }

    &__title {
        margin-bottom: 12px;
    }

    &__question {
        font-size: 1rem;
        margin-bottom: 6px;
        opacity: 0.8;
    }

    &__answer {
        font-size: 1.5rem;
        white-space: pre-line;
    }
}
