//====================================
// Outer container padding
//====================================

// Default x
$outer-padding: 22px;
$outer-padding-medium: 50px;
$outer-padding-large: 9vw;
$outer-padding-xlarge: 12vw;

// Default y
$outer-padding-y: 80px;
$outer-padding-y-medium: 100px;
$outer-padding-y-large: 105px;
$outer-padding-y-xxlarge: 105px;

//====================================
// Left inset
//====================================
$left-inset: 0;
$left-inset-medium: 60px;
$left-inset-large: 100px;

//====================================
// Grid variables (zurb foundation)
//====================================

// The maximum width of a grid container.
/// @type Number
$grid-container: 100%;

// The number of columns used in the grid.
/// @type Number
$grid-columns: 12;

// Gutter function
@function gutter($type, $key) {
    @if map-has-key($type, $key) {
        @return map-get($type, $key);
    }

    @warn "Unknown `#{$key}` in $grid-column-gutter.";
    @return null;
}
